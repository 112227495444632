<!-- @format -->
<template>
  <div>
    <div
      class="bg-white w-full flex rounded-md mb-4 py-2 px-4 items-center justify-between gap-2 border border-gray-100 shadow-sm cursor-pointer"
      @click="navigateToModelDay()"
    >
      <div v-if="loaderProgressBar" class="px-2 py-3">
        <div
          role="status"
          class="max-w-md space-y-4 divide-y divide-gray-200 rounded animate-pulse"
        >
          <div class="flex items-center justify-between">
            <div
              class="w-16 h-16 bg-gray-100 rounded-full dark:bg-gray-400 flex justify-center items-center"
            >
              <div class="w-12 h-12 bg-white rounded-full"></div>
            </div>
            <div>
              <div
                class="w-32 h-2 bg-gray-100 rounded-full dark:bg-gray-400 mb-2.5 ml-5"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div
          class="flex items-center gap-2"
          v-if="modelDayInstanceCount?.instances_count?.length > 0"
        >
          <RadialProgressBar
            :progress="modelDayInstanceCount?.instances_count[0]?.pending"
            :total="
              parseInt(modelDayInstanceCount?.instances_count[0]?.submitted) +
              parseInt(modelDayInstanceCount?.instances_count[0]?.pending)
            "
            :hasCenterText="true"
            :progressBarColor="'rgba(98, 178, 253, 1)'"
          />
          <div class="text-xl font-semibold">{{ currentShift }}</div>
        </div>
      </div>
      <div v-if="loaderProgressBar">
        <div
          class="w-32 h-2 bg-gray-100 rounded-full dark:bg-gray-400 mb-2.5 ml-5"
        ></div>
      </div>
      <div v-else class="bg-blue px-3 py-1 rounded-md text-blue bg-opacity-10">
        {{ shiftStatus }}
      </div>
    </div>
    <div
      class="xl:relative rounded overflow-hidden shadow-lg h-auto border-0 border-gray-100 py-10 bg-white"
    >
      <div class="flex justify-between">
        <div class="pl-18">
          <span
            class="text-4xl text-textBlue-Default font-medium tracking-tighter"
            >Survey Responses</span
          >
        </div>
        <div class="flex space-x-4 justify-end pr-10 w-2/3 cursor-pointer">
          <v-select
            class="w-72"
            label="label"
            placeholder="Show By"
            :searchable="false"
            :disabled="statsLoaded === true"
            :options="shoyByOptions"
            v-model="surveyResponseOrder"
          >
          </v-select>
          <v-select
            class="w-72"
            label="practice_name"
            placeholder="Practice"
            :disabled="statsLoaded === true"
            :options="practices"
            @update:modelValue="surveyResponsePractice"
          >
          </v-select>
          <v-select
            class="w-72"
            label="question"
            placeholder="Questions"
            :options="surveyQuestions"
            @update:modelValue="surveyResponseQuestion"
          >
          </v-select>
        </div>
      </div>
      <span class="text-4xl font-medium text-textBlue-Default pl-18">{{
        surveyCount
      }}</span>
      <div :key="surveyResponseOrder" v-if="statsLoaded === false">
        <SurveyResponseChart
          :key="optionStats"
          :order="surveyResponseOrder?.value"
          :data="optionStats"
          class="h-full"
        />
      </div>
      <div v-else>
        <SurveyPracticeChart
          :key="practiceStats"
          :data="practiceStats"
          class="h-full"
        />
      </div>
    </div>
    <div class="pt-10">
      <span class="text-4xl text-textBlue-Default font-medium tracking-tighter"
        >Compliances</span
      >
      <div class="flex gap-8 mt-6">
        <ComplianceList
          :key="getLatestItems('audits')"
          :heading="'Audits'"
          :loader="loader"
          :items="getLatestItems('audits')"
          :isDash="true"
        />
        <ComplianceList
          :heading="'Risk Assessment'"
          :loader="loader"
          :items="getLatestItems('risk-assessment')"
          :key="getLatestItems('risk-assessment')"
          :isDash="true"
        />
        <ComplianceList
          :heading="'Policies'"
          :loader="loader"
          :items="getLatestItems('policies')"
          :key="getLatestItems('policies')"
          :isDash="true"
        />
      </div>
    </div>

    <div class="flex gap-5 justify-between mt-10">
      <div class="border-2 border-gray-100 bg-white rounded-xl w-2/3">
        <div
          class="py-4 px-5 font-medium text-gray-700 border-gray-100 border-b-2 text-lg"
        >
          Recent Compliments
        </div>
        <div
          class="flex-col px-5 justify-center py-5 border border-gray-200 border-t-1 border-b-0 border-l-0 border-r-0"
          v-for="(compliment, index) in latestCompliment"
          :key="compliment"
        >
          <div :key="isReadMore">
            {{
              compliment?.compliment?.length > 110
                ? isReadMore[index]
                  ? compliment?.compliment
                  : compliment?.compliment?.slice(0, 110)
                : compliment?.compliment
            }}<span
              v-if="compliment?.compliment?.length > 110"
              class="text-blue-600 hover:underline cursor-pointer"
              @click="isReadMore[index] = !isReadMore[index]"
              >{{ isReadMore[index] ? "...Read Less" : "...Read More" }}</span
            >
          </div>
          <div class="text-gray-700">
            {{ compliment?.practice?.practice_name }}
          </div>
        </div>
        <div
          class="flex py-4 px-5 text-gray-500 font-medium border-gray-100 border-t-2 justify-between"
        >
          See Complete Overview
          <div
            class="flex items-center text-teal font-semibold opacity-70 cursor-pointer float-right"
            @click="routeToCompliments()"
          >
            Open
            <div class="material-icons text-lg ml-1">launch</div>
          </div>
        </div>
      </div>

      <ComplaintStatsChart
        class="w-1/3 border border-gray-100"
        :stats="complaintTypeStats"
        @at-filter-change="complaintStatFilterChange"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed, watchEffect } from "vue";
import SurveyResponseChart from "@/components/newUi/charts/SurveyResponseChart.vue";
import ComplianceList from "@/components/newUi/Compliance/ComplianceList.vue";
import ComplaintStatsChart from "@/components/newUi/Dashboard/ComplaintStatsChart.vue";
import RadialProgressBar from "@/components/newUi/baseComponents/RadialProgressBar.vue";
import SurveyPracticeChart from "@/components/newUi/charts/SurveyPracticeChart.vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

const router = useRouter();
const store = useStore();
const isReadMore = ref([]);
const surveyResponseOrder = ref(null);
const practiceStats = ref(null);
const practices = ref([]);
const statsLoaded = ref(false);
const loader = ref(false);
const loaderProgressBar = ref(false);
const shoyByOptions = ref([
  { label: "Ascending", value: "asc" },
  { label: "Descending", value: "desc" },
]);

const complaintStatsFilters = ref("daily");

const complaintStatFilterChange = (filter) => {
  complaintStatsFilters.value = filter;
};

const navigateToModelDay = () => {
  router.push({ name: "hq-monitoringOfPracticeFunctionsModelDayDashboard" });
};

const routeToCompliments = () => {
  router.push({
    name: "hq-monitoringOfPracticeFunctionComplimentDashboard",
    params: { isManager: 0 },
  });
};

const surveyResponsePractice = async (practice) => {
  surveyResponseOrder.value = null;
  await store.dispatch("patientSurveys/fetchOptionStats", {
    practice: practice ? practice.id : practice,
  });
};

const surveyResponseQuestion = async (question) => {
  if (question) {
    await store
      .dispatch("patientSurveys/fetchPracticeStats", {
        survey_question: question?.id,
        page: 1,
      })
      .then((response) => {
        statsLoaded.value = true;
        practiceStats.value = response.data;
      });
  } else {
    statsLoaded.value = false;
  }
};

const surveyQuestions = computed(() => {
  return store.getters["patientSurveys/getQuestionStats"];
});

const complianceCalenderItems = computed(() => {
  return store.getters["hqComplianceCalender/getComplianceCalenderItems"];
});

const optionStats = computed(() => {
  return store.getters["patientSurveys/getOptionStats"];
});

const surveyCount = computed(() => {
  return store.getters["patientSurveys/getsurveyCount"];
});

watchEffect(async () => {
  await store.dispatch("hqComplaints/fetchComplaintsTypeStats", {
    filter: complaintStatsFilters.value,
  });
});

const latestCompliment = computed(() => {
  if (compliments.value && compliments.value?.data?.length > 0)
    return compliments.value.data.slice(0, 5);
  return null;
});

const getLatestItems = (category) => {
  return computed(() => {
    const items = complianceCalenderItems.value[category]?.items;

    return items ? { items: items.slice(0, 3) } : null;
  });
};

const currentShift = computed(() => {
  switch (modelDayInstanceCount.value.shift) {
    case 1:
      return "Morning 8:00 AM";
    case 2:
      return "Mid-Day 11:00 AM";
    case 3:
      return "Evening 3:00 PM";
    default:
      return "Morning 8:00 AM";
  }
});

const shiftStatus = computed(() => {
  if (
    modelDayInstanceCount.value.instances_count &&
    modelDayInstanceCount.value.instances_count.length > 0
  ) {
    if (parseInt(modelDayInstanceCount.value.instances_count[0].pending) > 0) {
      return "In-Progress";
    } else {
      return "Completed";
    }
  }
  return "In-Progress";
});

const modelDayInstanceCount = computed(() => {
  return store.getters["hqModelDay/getDashInstanceCount"];
});

const complaintTypeStats = computed(() => {
  return [store.getters["hqComplaints/getComplaintTypeStats"]];
});

const compliments = computed(() => {
  return store.getters["hqCompliments/getAllCompliments"];
});
onMounted(async () => {
  loaderProgressBar.value = true;
  loader.value = true;
  await store.dispatch("hqCompliments/fetchAllCompliments", {
    page: 1,
  });
  await store.dispatch("patientSurveys/fetchSurveyCount");
  await store.dispatch("patientSurveys/fetchOptionStats");
  await store.dispatch("patientSurveys/loadPractices").then((response) => {
    practices.value = response;
  });
  await store.dispatch("patientSurveys/fetchQuestionStats");

  await store
    .dispatch("hqComplianceCalender/fetchComplianceCalenderItems")
    .then(() => {
      loader.value = false;
    });

  await store.dispatch("hqModelDay/fetchDashInstanceCount").then(() => {
    loaderProgressBar.value = false;
  });
});
</script>

<style scoped>
::v-deep .vs__dropdown-toggle {
  padding: 8px;
  background-color: white;
  cursor: pointer !important;
}
::v-deep ul li:not(:hover) {
  background-color: white !important;
  color: black;
}
::v-deep ul li:hover {
  background-color: teal !important;
  color: white;
}

::v-deep .vs__selected-options {
  flex-wrap: nowrap;
  max-width: calc(100% - 40px);
}

::v-deep .vs__selected {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 95%;
  overflow: hidden;
}
::v-deep ul li {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
